import React from 'react'
import { graphql } from 'gatsby'
import sanityClient from '@sanity/client'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import LoginHoc from '../hocs/LoginHoc'
import NetworkingDirSelection from '../components/NetworkingDir/NetworkingDirSelection'

import { notNilOrEmpty } from '../lib/Helpers'

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_SANITY_TOKEN,
  useCdn: false // `false` if you want to ensure fresh data
})

const titleTypeConfig = {
  new: {
    seo: 'Networking Directory Registration',
    hero: 'Registration'
  },
  edit: {
    seo: 'Edit Networking Directory',
    hero: 'Edit Entries'
  },
  selection: {
    seo: 'Networking Directory',
    hero: 'Networking Directory'
  }
}

export default props => {
  const [titleType, setTitleType] = React.useState('selection')

  const {
    data: { sanityPageContent: pageContent }
  } = props

  const setIsNewBusinessCardState = React.useCallback(
    user => {
      if (user.businessCard != null && user.businessCard._id != null) {
        setTitleType('edit')
        return
      }

      if (
        Array.isArray(user.businessCards) &&
        user.businessCards.length > 0 &&
        !user.businessCard
      ) {
        setTitleType('selection')
        return
      }

      setTitleType('new')
    },
    [titleType]
  )

  return (
    <Layout>
      <SEO
        title={titleTypeConfig[titleType].seo}
        keywords={pageContent.pageSeo.keywords}
        description={pageContent.description}
        author={pageContent.pageSeo.author}
        image={pageContent.pageSeo.seo_image.asset.url}
        url={`https://nasa.collaboration.ai/manage-network-directory`}
      />
      <Hero
        title=""
        className=""
        img={
          notNilOrEmpty(pageContent.hero_image) &&
          pageContent.hero_image.asset.url
        }
      />
      <LoginHoc
        sanityClient={client}
        onSuccessfulAuthentication={setIsNewBusinessCardState}
        onSignOut={() => setTitleType('new')}
      >
        <NetworkingDirSelection
          client={client}
          onSuccessfullFirstBusinessCardCreation={setIsNewBusinessCardState}
        />
      </LoginHoc>
    </Layout>
  )
}
export const query = graphql`
  query {
    sanityPageContent {
      hero_image {
        asset {
          url
        }
      }
      logo {
        asset {
          fluid(maxHeight: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
      page_transition_logo {
        asset {
          url
        }
      }
      pageSeo {
        author
        description
        keywords
        seo_image {
          asset {
            url
          }
        }
      }
    }
  }
`
