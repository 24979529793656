import * as R from 'ramda'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'

import { DropzoneArea } from 'material-ui-dropzone'
import Swal from 'sweetalert2'

import { notNilOrEmpty } from '../../lib/Helpers'
import BusinessCard from '../../services/BusinessCard'
import NetworkingDirDropdown from './NetworkingDirDropdown'

const INITIAL_BC_STATE = {
  title: '',
  company_phone: '',
  company_primary_contact: '',
  company_primary_contact_email: '',
  company_url: '',
  company_img: null,
  company_img_name: '',
  solution_id: '',
  phase: '',
  bc__who_are_you: ''
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  divider: {
    margin: '0 1rem'
  },
  companyImage: {
    width: '100px',
    height: '100px',
    margin: 'auto',
    marginBottom: '1rem'
  },
  exhibitorImageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column'
  },
  filesContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
    flexWrap: 'wrap'
  },
  fileContainer: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    padding: '0.5rem'
  },
  fileIcon: {
    margin: 'auto',
    marginBottom: '1rem'
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: 'rgba(255,255,255,0.7)',
    zIndex: 1000,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  },
  loader: {
    margin: 'auto'
  }
}))

const getFormInitialState = businessCard => {
  if (businessCard === 'new' || !businessCard) {
    return INITIAL_BC_STATE
  }

  return {
    company_phone: businessCard.company_phone || '',
    company_primary_contact: businessCard.company_primary_contact || '',
    company_primary_contact_email:
      businessCard.company_primary_contact_email || '',
    company_url: businessCard.company_url || '',
    phase: businessCard.sbir_program || '',
    ...businessCard
  }
}

export default ({
  client,
  session,
  onSignOut,
  setUserBusinessCard,
  businessCard,
  businessCards,
  fetchBusinessCards,
  ...props
}) => {
  const classes = useStyles()
  const [isLoading, setIsLoadingState] = React.useState(false)
  const [formData, setFormData] = React.useState(
    getFormInitialState(businessCard)
  )

  React.useEffect(() => {
    const el =
      typeof document !== 'undefined' && document.getElementById('bc-form')
    typeof document !== 'undefined' &&
      window.scroll({ top: el.offsetTop - 150, behavior: 'smooth' })
  }, [])

  React.useEffect(() => {
    const isDifferentRevision =
      '_rev' in formData &&
      businessCard != null &&
      businessCard._rev !== formData._rev
    const isNewBusinessCard =
      !('_id' in formData) && businessCard != null && businessCard._id != null
    if (isDifferentRevision || isNewBusinessCard) {
      setFormData(getFormInitialState(businessCard))
    }
  }, [businessCard])

  function _handleInputChange(e) {
    e.preventDefault()

    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  function _handleChecked(e) {
    e.preventDefault()

    setFormData({
      ...formData,
      [e.target.name]: e.target.checked
    })
  }

  function _handleAddingImage(id, e) {
    const name = `${id}_name`
    notNilOrEmpty(e) &&
      setFormData({
        ...formData,
        [id]: e[0],
        [name]: e[0].name
      })
  }

  async function _handleForm(e) {
    try {
      e.preventDefault()
      setIsLoadingState(true)
      // creating the object to send to sanity
      const nextBusinessCard = new BusinessCard(
        formData,
        client,
        session.user._id,
        getFormInitialState(businessCard)
      )
      const businessCardRecord = await (nextBusinessCard.isNew()
        ? nextBusinessCard.createBusinessCard()
        : nextBusinessCard.updateBusinessCard())

      Swal.fire({
        title: 'Thank you for your Virtual Business Card submission.',
        text:
          'We will review it and publish it to the site as soon as possible.',
        icon: 'success',
        showConfirmButton: false,
        confirmButtonText: 'Send',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
      setUserBusinessCard(businessCardRecord)
      setIsLoadingState(false)
      fetchBusinessCards()
    } catch (error) {
      console.error('ERROR', error)
      setIsLoadingState(false)
      Swal.fire({
        title: 'Failed to save your information.',
        text: 'Please try again or contact support!',
        icon: 'error',
        showConfirmButton: false,
        confirmButtonText: '',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
    }
  }

  const removeBusinessCardResource = (unset, resourceType) => {
    setIsLoadingState(true)
    client
      .patch(formData._id)
      .unset(unset)
      .commit()
      .then(async res => {
        const businessCardRecord = new BusinessCard({}, client, null, {})
        const updatedBusinessCard = await businessCardRecord.getLatestDocument(
          formData._id
        )
        setUserBusinessCard(updatedBusinessCard)
        setIsLoadingState(false)
        Swal.fire({
          title: 'Record Deleted',
          text: `The requested ${resourceType} was deleted successfuly.`,
          icon: 'success',
          showConfirmButton: false,
          confirmButtonText: 'Send',
          showCloseButton: true,
          heightAuto: false,
          padding: '3em',
          customClass: {
            popup: 'popup',
            confirmButton: 'btn btn--black btn-confirm'
          }
        })
      })
      .catch(err => {
        console.error('Delete failed: ', err.message)
        setIsLoadingState(false)
        Swal.fire({
          title: `Failed to delete the requested ${resourceType}.`,
          text: 'Please try again or contact support!',
          icon: 'error',
          showConfirmButton: false,
          confirmButtonText: '',
          showCloseButton: true,
          heightAuto: false,
          padding: '3em',
          customClass: {
            popup: 'popup',
            confirmButton: 'btn btn--black btn-confirm'
          }
        })
      })
  }

  function _createNewBusinessCard() {
    setUserBusinessCard('new')
    setFormData(getFormInitialState('new'))
  }

  return (
    <div className="container exhibitor-page page--new-exhibitor">
      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.loader} />
        </div>
      )}
      <div
        className={`animated fadeIn section-content form-container-alignment`}
      >
        {notNilOrEmpty(session) && notNilOrEmpty(session.user) ? (
          <Box
            display="flex"
            p={1}
            flexDirection="row"
            alignItems="center"
            justifyItems="space-between"
            className="form-top-section"
          >
            <div className="form-top-section--left">
              <Typography variant="caption">
                Signed In As: {session.user.email}
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
              />
              <Typography variant="caption">
                <Link href="#" onClick={onSignOut}>
                  Sign Out
                </Link>
              </Typography>
            </div>
            <div className="form-top-section--right">
              <Button
                onClick={_createNewBusinessCard}
                className="btn--yellow"
                variant="contained"
                color="primary"
              >
                Add New
              </Button>
              {notNilOrEmpty(session.user) && notNilOrEmpty(businessCards) && (
                <NetworkingDirDropdown
                  businessCards={businessCards}
                  selectedBusinessCardId={businessCard._id}
                  setSelectedBusinessCard={setUserBusinessCard}
                />
              )}
            </div>
          </Box>
        ) : null}
        <div className="form-container">
          <form onSubmit={_handleForm} id="bc-form">
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                label="Company"
                variant="outlined"
                color="secondary"
                name="title"
                onChange={_handleInputChange}
                value={formData.title}
                required
                error={
                  R.equals(businessCard, 'new')
                    ? R.gt(R.length(formData.title), 79)
                    : null
                }
                helperText={
                  R.equals(businessCard, 'new')
                    ? R.gt(R.length(formData.title), 79) &&
                      `(79 characters limit) / ${79 -
                        R.length(formData.title)} chars left`
                    : ''
                }
              />
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                helperText={`(150 char limit) / ${150 -
                  R.length(formData.bc__who_are_you)} chars left`}
                label="Brief Summary of your Company"
                variant="outlined"
                color="secondary"
                name="bc__who_are_you"
                onChange={_handleInputChange}
                value={formData.bc__who_are_you}
                multiline
                rows={8}
                required
                error={R.gte(
                  R.length(
                    formData.bc__who_are_you ? formData.bc__who_are_you : 0
                  ),
                  150
                )}
              />
            </FormControl>
            <InputLabel required className="form-input" id="logo-label">
              Company Logo
            </InputLabel>
            {formData._id != null && formData.company_img_url != null && (
              <div className={classes.exhibitorImageContainer}>
                <img
                  src={formData.company_img_url}
                  alt="Company Logo"
                  className={classes.companyImage}
                />
                <Button
                  color="secondary"
                  onClick={() => removeBusinessCardResource(['company_img'])}
                >
                  Remove
                </Button>
              </div>
            )}
            <FormControl variant="outlined" className="form-input">
              <DropzoneArea
                required
                acceptedFiles={['image/*']}
                dropzoneText={'Drag and drop your company logo here or click'}
                filesLimit={1}
                id="image-path"
                name="company_img"
                onChange={e => _handleAddingImage('company_img', e)}
                initialFiles={[formData.company_img]}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                color="secondary"
                error={
                  notNilOrEmpty(formData.company_url) &&
                  !R.startsWith('http', formData.company_url)
                }
                helperText="Please include http:// or https://"
                label="Company Website"
                name="company_url"
                onChange={_handleInputChange}
                value={formData.company_url}
                variant="outlined"
              />
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                label="Primary Contact"
                variant="outlined"
                color="secondary"
                name="company_primary_contact"
                onChange={_handleInputChange}
                value={formData.company_primary_contact}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                label="Primary Contact Email"
                variant="outlined"
                color="secondary"
                type="email"
                name="company_primary_contact_email"
                error={
                  notNilOrEmpty(formData.company_primary_contact_email) &&
                  R.not(
                    notNilOrEmpty(
                      R.match(
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        formData.company_primary_contact_email
                      )
                    )
                  )
                }
                onChange={_handleInputChange}
                value={formData.company_primary_contact_email}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                label="Phone Number"
                variant="outlined"
                color="secondary"
                name="company_phone"
                onChange={_handleInputChange}
                value={formData.company_phone}
              />
            </FormControl>
            <FormControl
              component="fieldset"
              variant="outlined"
              className="form-input"
            >
              <FormLabel component="legend" style={{ paddingBottom: 20 }}>
                From the following list, please select the category that best
                matches your organization
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="phase"
                onChange={_handleInputChange}
                value={formData.phase}
                className="radio-group"
              >
                <FormControlLabel
                  value="phase_0"
                  control={<Radio />}
                  label="Small business that is interested in applying to the NASA SBIR/STTR solicitation or that has received a Phase 1 contract"
                />
                <FormControlLabel
                  value="phase_2"
                  control={<Radio />}
                  label="Small business that has received a Phase 2 contract and is interested in post Phase 2 or Phase 3 opportunities"
                />
                <FormControlLabel
                  value="research"
                  control={<Radio />}
                  label="Research institution"
                />
                <FormControlLabel
                  value="investor"
                  control={<Radio />}
                  label="Large business or potential investor"
                />
                <FormControlLabel
                  value="government"
                  control={<Radio />}
                  label="Government"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={_handleForm}
                  className={classes.button}
                  disabled={R.not(
                    R.and(
                      R.and(
                        R.and(
                          notNilOrEmpty(formData.title),
                          R.cond([
                            [
                              R.equals('new'),
                              R.always(R.lte(R.length(formData.title), 79))
                            ],
                            [R.is(Object), R.always(true)],
                            [R.T, R.T]
                          ])(businessCard)
                        ),
                        R.and(
                          notNilOrEmpty(formData.bc__who_are_you),
                          R.lte(
                            R.length(
                              formData.bc__who_are_you
                                ? formData.bc__who_are_you
                                : 0
                            ),
                            150
                          )
                        )
                      ),
                      notNilOrEmpty(formData.company_img)
                    )
                  )}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
