import React from 'react'
import * as R from 'ramda'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import NetworkingDirForm from './NetworkingDirForm'
import NetworkingDirDropdown from './NetworkingDirDropdown'

import { notNilOrEmpty } from '../../lib/Helpers'

const useStyles = makeStyles(theme => ({
  button: {
    margin: '20px auto',
    width: 'auto'
  },
  actionsContainer: {
    width: '100%',
    margin: `2rem 0`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column'
  }
}))

export default ({
  children,
  onSuccessfullFirstBusinessCardCreation,
  ...props
}) => {
  const classes = useStyles()
  const [businessCards, setBusinessCardsList] = React.useState(
    Array.isArray(businessCards) ? businessCards[0] : []
  )
  const [selectedBusinessCard, setSelectedBusinessCard] = React.useState()

  const setUserBusinessCard = React.useCallback(bc => {
    setSelectedBusinessCard(bc)
    onSuccessfullFirstBusinessCardCreation({
      ...props.session.user,
      businessCard: bc
    })
  }, [])

  const businessCardFormProps = {
    ...props,
    setUserBusinessCard,
    businessCards
  }

  async function currentUserBusinessCards() {
    const fetchBusinessCards = await props.client
      .fetch(
        `*[_type == "networkingDirectory" && user._ref == "${props.session.user._id}"] {
          'company_img_url': company_img.asset->url,
          ...
        }`
      )
      .catch(reason => {
        // Track the reason here
        console.log(reason)
        if (typeof window !== 'undefined') {
          window.alert(
            'Failed to login, our service might be down, apologies for the inconvenience.'
          )
        }
      })

    setBusinessCardsList(fetchBusinessCards)
  }

  React.useEffect(() => {
    currentUserBusinessCards()
  }, [])

  React.useEffect(() => {
    if (
      !selectedBusinessCard &&
      Array.isArray(currentUserBusinessCards) &&
      currentUserBusinessCards.length === 1
    ) {
      setSelectedBusinessCard(currentUserBusinessCards[0])
    }

    if (
      Array.isArray(currentUserBusinessCards) &&
      R.gt(R.length(currentUserBusinessCards), 1)
    ) {
      setBusinessCardsList([currentUserBusinessCards])
    }
  }, [props.session])

  if (!Array.isArray(businessCards)) {
    return (
      <NetworkingDirForm
        {...businessCardFormProps}
        businessCards={businessCards}
        fetchBusinessCards={currentUserBusinessCards}
      />
    )
  }

  if (!selectedBusinessCard) {
    return (
      <div display="flex" p={1} flexdirection="column" alignitems="center">
        <div className="form-container-alignment">
          {notNilOrEmpty(props.session.user) &&
            notNilOrEmpty(businessCards) && (
              <NetworkingDirDropdown
                businessCards={businessCards}
                setSelectedBusinessCard={setUserBusinessCard}
              />
            )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setUserBusinessCard('new')}
            className={classes.button}
          >
            Submit to Networking Directory
          </Button>
        </div>
      </div>
    )
  }

  return (
    <NetworkingDirForm
      {...businessCardFormProps}
      businessCards={businessCards}
      businessCard={selectedBusinessCard}
      fetchBusinessCards={currentUserBusinessCards}
    />
  )
}
