import slugify from '@sindresorhus/slugify'

export default class BusinessCard {
  constructor(businessCard, sanityClient, userId, original) {
    this.userId = userId
    this.sanityClient = sanityClient
    this.businessCard = { ...businessCard }
    this._original = businessCard._id != null ? { ...original } : {}
  }

  isNew() {
    return !this.businessCard._id
  }

  toCreate() {
    const slug = `${slugify(
      this.businessCard.title
    )}-${new Date().getTime().toString(36)}`

    const validation = {}

    return {
      _id: `drafts.${slug}`,
      _type: 'networkingDirectory',
      title: this.businessCard.title,
      company_phone: this.businessCard.company_phone,
      company_primary_contact: this.businessCard.company_primary_contact,
      company_primary_contact_email: this.businessCard
        .company_primary_contact_email,
      company_url: this.businessCard.company_url,
      slug: {
        type: 'slug',
        current: slug
      },
      bc__who_are_you: this.businessCard.bc__who_are_you,
      phase: this.businessCard.phase,
      user: {
        _ref: this.userId,
        _key: this.userId,
        _type: 'reference'
      },
      ...validation
    }
  }

  toUpdate() {
    let updates = {}

    return {
      _id: this.businessCard._id,
      _type: 'networkingDirectory',
      title: this.businessCard.title,
      company_phone: this.businessCard.company_phone,
      company_primary_contact: this.businessCard.company_primary_contact,
      company_primary_contact_email: this.businessCard
        .company_primary_contact_email,
      company_url: this.businessCard.company_url,
      bc__who_are_you: this.businessCard.bc__who_are_you,
      phase: this.businessCard.phase,
      ...updates
    }
  }

  async createBusinessCard() {
    try {
      const doc = this.toCreate()
      const newBusinessCard = await this.sanityClient.create(doc)
      await this.sanityClient.assets
        .upload('image', this.businessCard.company_img, {
          filename: this.businessCard.company_img_name
        })
        .then(imageAsset => {
          return this.sanityClient
            .patch(newBusinessCard._id)
            .set({
              company_img: {
                _type: 'image',
                asset: {
                  _type: 'reference',
                  _ref: imageAsset._id
                }
              }
            })
            .commit()
        })
      return this.getLatestDocument(newBusinessCard._id)
    } catch (error) {
      throw error
    }
  }

  async updateBusinessCard() {
    try {
      const { _id, ...updates } = this.toUpdate()
      const updatedBusinessCard = await this.sanityClient
        .patch(_id)
        .set(updates)
        .commit()
      if (this._original.company_img !== this.businessCard.company_img) {
        await this.sanityClient.assets
          .upload('image', this.businessCard.company_img, {
            filename: this.businessCard.company_img_name
          })
          .then(imageAsset => {
            return this.sanityClient
              .patch(_id)
              .set({
                company_img: {
                  _type: 'image',
                  asset: {
                    _type: 'reference',
                    _ref: imageAsset._id
                  }
                }
              })
              .commit()
          })
      }
      return this.getLatestDocument(_id)
    } catch (error) {
      throw error
    }
  }

  async getLatestDocument(id) {
    const results = await this.sanityClient
      .fetch(
        `*[_id == "${id}"] {
          'company_img_url': company_img.asset->url,
          ...
        }`
      )
      .catch(reason => {
        // Track the reason here
        console.log(reason)
        if (typeof window !== 'undefined') {
          window.alert(
            'Failed to fetch a business card record for your account, our service might be down, apologies for the inconvenience.'
          )
        }
      })
    // console.log('RESULTS', results)
    return results[0]
  }
}
